<template>
    <div class="contenter" style="height:100%;padding-top:88px;padding-bottom:54px;">
        <Header style="margin-top:-88px;position: relative;"></Header>
        <div style="min-height:100%;width:100%;backgroup:#fdfdfd;">
            <div class="probanner">
                <div class="probannerInner product">
                    <div class="banner_img ">
                        <div class="titleBan">
                            <span class="tit">产品介绍</span> 
                            <span class="con">/PRODUCT</span>
                        </div>            
                        <div class="contentBan">致力于为用户提供简单、专业、高品质的数据解决方案</div>            
                    </div>
                </div>
            </div>
            <div class="prowrap prowrapMinHeight">
                <div class="proside prosideMinHeight cleft">
                    <ul class="sideGourp" >
                        <li class="sideItem" v-for="(item,key) in sideList" :key="key">
                            <div class="title" :class="sideActive==item.id?'current':''" @click="getGroupClick(item)">
                                <span :class="sideActive==item.id?'cheks':''"></span>
                                <span>{{item.name}}<font style="color:red;">&nbsp;{{item.fonts}}</font></span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="procontent cright">
                    <div class="CommontTitle" v-show="sideActive==1">
                        <div class="com_TitleName">
                            <span class="com_Name_s" :class="epsTitle?'current':''" v-show="!nameobj.name" >
                                EPS数据平台
                            </span>
                            <span class="com_Name_s" :class="epsTitle?'current':''" v-show="nameobj.name" >
                                {{nameobj.name}}
                            </span>
                            <a :href="olap_url+'#/login'" class="linkbtn" target="_blank">
                                进入平台<i class="gwicon more"></i>
                            </a>
                        </div>
                        <div class="titleInner" v-show="epsTitle" @mouseover.stop="getEPSlist" @mouseout.stop="getEPSlists">
                            <div class="groupDB">
                                <div class="itemDb" v-for="(v,k) in sidechild" :key="k">
                                    <div class="idbleft">{{v.name}}<i class="gwicon lics"></i></div>
                                    <div class="idbright">
                                        <span v-for="(i,t) in v.cubes" :key="t" @click.stop="getItemEPSlist(v,i)">{{i.fullName}}</span>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- EPS数据平台 -->
                    <div class="problock" v-show="sideActive==1&&sideActiveItem==0">
                        <div class="paragraph-box">
                            <p class="paragraph">EPS（Express Professional Superior）数据平台是集丰富的数值型数据资源和强大经济计量系统为一体的覆盖多学科、面向多领域的综合性统计数据与分析平台。平台涵盖宏观数据、市县数据、新概念数据和热门主题数据四大核心版块，包含{{dataEPSobj.CUBE_NUMS}}个数据库，超过{{dataEPSobj.SEQ_NUM}}亿条时间序列数据，数据总量超过{{dataEPSobj.TOTAL_NUM}}亿条，每年新增近10亿条。</p>
                            <p class="paragraph">在深度整合海量数据资源的基础上，EPS数据平台还为用户提供数据检索、数据处理、云分析、建模预测、数据可视化和个人中心于一体的强大系统功能，极大地实现了数据的全面覆盖与深入分析，全方位提升数据应用的成果价值，为高等院校、科研院所、金融机构、政府部门、企事业单位在教学、科研、投资与决策方面提供强有力的数据支撑。</p>
                            <p class="paragraph" style="display:none;">EPS（Easy Professional Superior）数据平台，是集丰富的数值型数据资源和强大的经济计量系统为一体的数据信息服务平台，平台大规模集成整合了各类数据资源，形成国际数据、宏观经济、金融市场、产业运行、区域经济、贸易外经、资源环境、县市数据、人文社科、普查数据多个数据库集群，包含86个数据库，10亿+条时间序列，数据总量超40亿条，并且以每年2亿条左右的速度递增。</p>
                            <p class="paragraph" style="display:none;">EPS数据平台拥有中、英文双语版本，其开发设计参考SAS、SPSS等国际著名统计分析软件的设计理念和标准，提供了集数据检索、数据处理、云分析、建模预测、可视化展现和个人中心于一体的强大系统功能，为高等院校、科研院所、金融机构、政府部门、企事业单位的教学、科研、投资与决策提供强有力的数据支持。</p>
                        </div>
                        <div class="wheelsWrap">
                            <div class="wheelTitle">
                                <div class="wheelName cleft" v-for="(v,k) in epsListData" :key="k">
                                    <div class="wheelTitImg">
                                        <img :src="v.src" :alt="v.title">
                                    </div>
                                    <div class="wheelTit">{{v.title}}</div>
                                    <div class="wheelPname">{{v.name1}}</div>
                                    <div class="wheelPname">{{v.name2}}</div>
                                </div>
                            </div>
                            <Epsdemo></Epsdemo>
                        </div>
                    </div>
                    
                    <div class="problock" v-show="sideActive==1">
                        <div class="CommontTitle" style="display:none">
                            <div class="com_TitleName">
                                <span class="com_Name">{{epsCubeObj}}</span>
                            </div>
                        </div>
                        <Vuetml :url.sync="epsCubeObj.url"></Vuetml>
                    </div> 
                    <!-- 中国微观经济数据查询系统 -->
                    <div class="problock" v-show="sideActive==2">
                        <div class="CommontTitle">
                            <div class="com_TitleName">
                                <span class="com_Name">中国微观经济数据查询系统</span>
                                <a :href="wgjj_rul" class="linkbtn" target="_blank">
                                    进入系统<i class="gwicon more"></i>
                                </a>
                            </div>
                        </div>
                        <div class="paragraph-box">
                            <p class="paragraph">中国微观经济数据查询系统改变了传统的以光盘为载体微观数据提供方式，并对数据进行了专业的清洗与处理，围绕数据获取与数据应用两大用户核心需求进行设计，包含“单年查询”、“时间序列”、“数据可视化”和“跨库查询”四大功能模块，是一款微观数据查询、统计与可视化展示平台，大大降低了数据的使用门槛，提升了数据的使用效率。</p>
                            <p class="paragraph" style="display:none;">中国微观经济数据查询系统是一款以微观数据为基础的数据查询、统计与可视化展示平台。</p>
                            <p class="paragraph" style="display:none;">在国家实施大数据战略的背景下，微观数据正成为重要的基础性战略资源，对学术研究与政策制定具有重要的作用。中国微观经济数据查询系统改变了传统的以光盘为载体的微观经济数据线下提供方式，并对数据进行了专业的清洗与处理，在很大程度上解决了数据异常、填报不准确，难于匹配等问题。同时增加了对财务信息指标的名词解释，大大降低了数据的使用门槛，提升了数据的使用效率。</p>
                            <p class="paragraph" style="display:none;">中国微观经济数据查询系统操作简单，围绕数据获取与数据应用两大用户核心需求进行设计，包含“单年查询”、“时间序列”、“数据可视化”和“跨库查询”四大功能模块。</p>                    
                        </div>
                        <div class="wheelsWrap">
                            <div class="wheelTitle">
                                <div class="wheelName cleft" v-for="(v,k) in wgListData" :key="k">
                                    <div class="wheelTitImg">
                                        <img :src="v.src" :alt="v.title">
                                    </div>
                                    <div class="wheelTit">{{v.title}}</div>
                                    <div class="wheelPname">{{v.name1}}</div>
                                    <div class="wheelPname">{{v.name2}}</div>
                                </div>
                            </div>
                            <Wgdemo></Wgdemo>
                        </div>
                    </div>
                    <!-- 知图学术平台 -->
                    <div class="problock" v-show="sideActive==6">
                        <div class="CommontTitle">
                            <div class="com_TitleName">
                                <span class="com_Name">知图平台</span>
                                <a href="https://zhitu.sozdata.com/" class="linkbtn" target="_blank">
                                    进入平台<i class="gwicon more"></i>
                                </a>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="textTip">
                                知图是基于AI、大数据、云计算等前沿信息科技自主研发的图情、学研及信息素养研究与实践平台，主要面向图情从业人员、专家学者及高校师生，提供一站式图情学研融合、知识集成共享、信息实践培育等综合服务，通过打造不同场景需求下的图情科研体系、信息素养培育体系、知识社交体系，以功能性平台为赋能载体，以信息化科技为推动引擎，致力于构建国内首个图情-学研-信息素养融合生态。
                            </div>
                            <div class="textTip" style="margin-top:20px;">
                                知图平台的创立，旨在积极响应《“十四五”国家信息化规划》《提升全民数字素养与技能行动纲要》等国家级政策及指导思想，聚焦图情人员工作科研要点以及高校系统化信息素养刚需，创新性开创学研中心、信息素养、图情视界三大主功能模块，下设选题指南、论文发现、国内外图情、知图内刊、学赛后台、资源中心、学习中心等十余个子功能模块，完整聚合链接图情学研应用、信息素养教育实践、知识社区交流共享等多维服务，合力促成学研融合、信息交互及知识社交相辅相成的生态闭环。
                            </div>
                            <div class="advantage">
                                <div class="text">产品优势</div>
                                <div class="advantageContent">
                                    <div class="item">
                                        <div class="image">
                                            <img src="~@/assets/adv1.png" alt="">
                                        </div>
                                        <div class="advTit">应用场景丰富，强大功能适配</div>
                                        <div class="advTxt">“信息素养”、"学研中心"、"图情视界"三大模块，满足用户在课程教学、赛事活动、学术研究、论文撰写、期刊投稿、信息素养水平提升以及社区交流互动等多样化场景下的广泛需求。</div>
                                    </div>
                                    <div class="item">
                                        <div class="image">
                                            <img src="~@/assets/adv2.png" alt="">
                                        </div>
                                        <div class="advTit">专业背书保障，权威内容矩阵</div>
                                        <div class="advTxt">与《图书情报工作》等图情核心期刊出版社、武汉大学信息管理学院黄如花教授建立深度战略合作关系，确保知图内容的权威性、专业性、系统性、前沿性。</div>
                                    </div>
                                    <div class="item">
                                        <div class="image">
                                            <img style="height: 53px;" src="~@/assets/adv3.png" alt="">
                                        </div>
                                        <div class="advTit">汇聚核心资源，首创融合生态</div>
                                        <div class="advTxt">平台完整聚合图情科研体系、信息素养培育体系、知识社交体系，实现系统化赋能、智慧化应用、信息化支撑，首创图情-学研-信息素养融合生态。</div>
                                    </div>
                                    <div class="item">
                                        <div class="image">
                                            <img src="~@/assets/adv4.png" alt="">
                                        </div>
                                        <div class="advTit">打造信息素养教育场景闭环</div>
                                        <div class="advTxt">搭建信息素养学习中心，构建全面、个性化、互动灵活的学习平台，实现教、学练、测、评五位一体；同时结合赛事中心及学赛后台开展信息素养比赛，完成信息素养教、学、练、测、评、赛场景闭环。</div>
                                    </div>
                                </div>
                            </div>
                            <div class="feature">
                                <div class="text">功能模块</div>
                                <div class="featureContent">
                                    <div class="item">
                                        <div class="tit">信息素养</div>
                                        <div class="desc">拥有海量的视频、试题等学习资源，打造信息素养教、学、练、测、评、赛完整闭环。</div>
                                        <div class="tags">
                                            <span>资源中心</span>
                                            <span>赛事中心</span>
                                            <span>实训中心</span>
                                            <span>学习中心</span>
                                            <span>学赛后台</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="tit">学研中心</div>
                                        <div class="desc">提供包括论文选题、文献推荐、论文查重、论文投稿在内容论文写作全流程服务。</div>
                                        <div class="tags">
                                            <span>选题指南</span>
                                            <span>论文查重</span>
                                            <span>论文发现</span>
                                            <span>论文投稿</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="tit">图情视界</div>
                                        <div class="desc">聚焦国内外图情发展前沿，甄选优质图情领域专家优质文章，共建图情领域学术共同体。</div>
                                        <div class="tags">
                                            <span>知图甄选</span>
                                            <span>海外图情</span>
                                            <span>国内图情</span>
                                            <span>知图内刊</span>
                                            <span>知图社区</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="guide">
                                <div class="text">操作指南</div>
                                <div class="guideContent">
                                    <div class="item">
                                        <div class="imgWrap" @click="tolink(0)">
                                            <img src="~@/assets/introduce2Teacher.png" alt="">
                                        </div>
                                        <div class="itemBtm">
                                            <span>教师创建考赛</span>
                                            <span class="down" @click="toPDF(0)">阅读PDF</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="imgWrap" @click="tolink(1)">
                                            <img src="~@/assets/introduce2Student.png" alt="">
                                        </div>
                                        <div class="itemBtm">
                                            <span>学生参加考赛</span>
                                            <span class="down" @click="toPDF(1)">阅读PDF</span>
                                        </div>
                                    </div>
                                    <!-- <div class="item">
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 长江经济带大数据平台 -->
                    <div class="problock" v-show="sideActive==4">
                        <div class="CommontTitle">
                            <div class="com_TitleName">
                                <span class="com_Name">长江经济带大数据平台</span>
                                <a :href="cjjjd_url" class="linkbtn" target="_blank">
                                    进入平台<i class="gwicon more"></i>
                                </a>
                            </div>
                        </div>
                        <div class="paragraph-box">                        
                            <p class="paragraph">长江经济带大数据平台提供多种资源，学科研究者可以直接从中获利，从而促进和完善社会科学的发展；各级政府通过平台能直接了解到治下区域发展状况，更有利于政府进行经济决策；能够通过平台对长江经济带的基本面做到运筹帷幄，全局把握，从宏观层面能及时纠正发展过程中呈现的问题，提高长江经济带发展效率，提升长江经济带建设质量。</p>
                            <p class="paragraph" style="display:none;">长江经济带作为国家战略，已经吸引了世界的瞩目，将成为中国未来发展的潜力空间。在大数据时代的今天，在长江经济带发展建设过程中，研究部门和政府部门都需要数据分析作为学术研究和科学决策的依据。因此，长江经济带大数据平台的建设具有重大意义，平台提供多种资源，学科研究者可以直接从中获利，从而促进和完善社会科学的发展；各级政府通过平台能直接了解到治下区域发展状况，更有利于政府进行经济决策；能够通过平台对长江经济带的基本面做到运筹帷幄，全局把握，从宏观层面能及时纠正发展过程中呈现的问题，提高长江经济带发展效率，提升长江经济带建设质量。</p>
                        </div>
                        <div class="wheelsWrap">
                            <div class="wheelTitle">
                                <div class="wheelName cleft" v-for="(v,k) in cjjjdListData" :key="k">
                                    <div class="wheelTitImg">
                                        <img :src="v.src" :alt="v.title">
                                    </div>
                                    <div class="wheelTit">{{v.title}}</div>
                                    <div class="wheelPname">{{v.name1}}</div>
                                    <div class="wheelPname">{{v.name2}}</div>
                                </div>
                            </div>
                            <Cjjjddemo></Cjjjddemo>
                        </div>
                    </div>
                    <!-- 中国革命老区数据中心 -->
                    <div class="problock" v-show="sideActive==5">
                        <div class="CommontTitle">
                            <div class="com_TitleName">
                                <span class="com_Name">中国革命老区大数据平台</span>
                                <a :href="gmlq_url" class="linkbtn" target="_blank">
                                    进入平台<i class="gwicon more"></i>
                                </a>
                            </div>
                        </div>
                        <div class="paragraph-box">
                            <p class="paragraph">中国革命老区大数据平台数据涉及中国五个跨省重点革命老区，八个连片革命老区和中央苏区，覆盖全国1500余个县级革命老区。包含“老区要闻”、“老区概况”、“精准扶贫”、“政策措施”、“电子期刊”、“指数监测”、“统计数据”、“微观数据”、“空间数据”、“调查数据”共十个专题模块，为国家战略探究提供强大的数据支撑。</p>
                            <p class="paragraph" style="display:none;">中国革命老区数据中心为研究国家战略提供强大的数据支撑</p>
                            <p class="paragraph" style="display:none;">中国革命老区数据平台集成多种数据资源，覆盖全国1500余个县级革命老区，并建立老区发展检测平台和指数平台，是一个革命老区主题研究的大数据平台。</p>
                            <p class="paragraph" style="display:none;">主要特点包括发布市级革命老区发展评价指数，同步检测指数指标；包含5个跨省重点革命老区，八个连片革命老区和中央苏区的数据；发布老区要闻，老区概况，精准扶贫等五个专题模块内容。</p>
                        </div>
                        <div class="wheelsWrap">
                            <div class="wheelTitle">
                                <div class="wheelName cleft" v-for="(v,k) in gmlqListData" :key="k">
                                    <div class="wheelTitImg">
                                        <img :src="v.src" :alt="v.title">
                                    </div>
                                    <div class="wheelTit">{{v.title}}</div>
                                    <div class="wheelPname">{{v.name1}}</div>
                                    <div class="wheelPname">{{v.name2}}</div>
                                </div>
                            </div>
                            <Gmlqdemo></Gmlqdemo>
                        </div>
                    </div>
                    <!-- 中国区域研究数据支撑平台 -->
                    <div class="problock" v-show="sideActive==7">
                        <div class="CommontTitle">
                            <div class="com_TitleName">
                                <span class="com_Name">中国区域研究数据支撑平台</span>
                                <a :href="qyjj_url" class="linkbtn" target="_blank">
                                    进入平台<i class="gwicon more"></i>
                                </a>
                            </div>
                        </div>
                        <div class="paragraph-box">
                            <p class="paragraph">中国区域研究数据支撑平台是一款以区域经济统计数据为基础，集数据查询、数据处理、数据可视化为一体的数据平台。平台涵盖了31个省、300多个城市、2800多个区县以及30000多个乡镇在经济和社会发展等方面的统计数据，支持一键选择，方便用户高效快捷筛选研究所需重点区域数据，为区域经济研究，特别是对各省区市、经济区、城市群、都市圈等相关领域研究提供强有力的数据支持。</p>
                            <p class="paragraph" style="display:none;">中国革命老区数据中心为研究国家战略提供强大的数据支撑</p>
                            <p class="paragraph" style="display:none;">中国革命老区数据平台集成多种数据资源，覆盖全国1500余个县级革命老区，并建立老区发展检测平台和指数平台，是一个革命老区主题研究的大数据平台。</p>
                            <p class="paragraph" style="display:none;">主要特点包括发布市级革命老区发展评价指数，同步检测指数指标；包含5个跨省重点革命老区，八个连片革命老区和中央苏区的数据；发布老区要闻，老区概况，精准扶贫等五个专题模块内容。</p>
                        </div>
                        <div class="wheelsWrap">
                            <div class="wheelTitle">
                                <div class="wheelName cleft" v-for="(v,k) in qyjjListData" :key="k">
                                    <div class="wheelTitImg">
                                        <img :src="v.src" :alt="v.title" style="height:100%">
                                    </div>
                                    <div class="wheelTit">{{v.title}}</div>
                                    <div class="wheelPname">{{v.name1}}</div>
                                    <div class="wheelPname">{{v.name2}}</div>
                                </div>
                            </div>
                            <Qyjjdemo></Qyjjdemo>
                        </div>
                    </div>
                    <!-- 知识服务平台 -->
                    <div class="problock" v-show="sideActive==3">
                        <div class="CommontTitle">
                            <div class="com_TitleName">
                                <span class="com_Name">知识服务平台</span>
                                <a :href="know_url" class="linkbtn" target="_blank">
                                    进入平台<i class="gwicon more"></i>
                                </a>
                            </div>
                        </div>
                        <div class="paragraph-box">                    
                            <p class="paragraph">EPS知识服务平台基于知识发现、应用集成、开放互联的理念进行设计，摒弃数据资源的简单堆砌，通过深挖异构数据内在联系，以及数据分析、智能表格分析、可视化图谱等知识发现工具的集成，着力解决广大读者在实证研究过程中存在的“从查阅文献到收集数据、从利用数据到得到结论”环节中所缺失的数据发现与数据分析服务。</p>
                            <p class="paragraph" style="display:none;">知识服务平台是北京福卡斯特信息技术有限公司采用全新数据服务模式自主研发的一款数据类知识服务平台。其宗旨在于解决广大读者在实证研究过程中存在的“从查阅文献到收集数据、从利用数据到得到结论”环节中所缺失的数据发现与数据分析服务。</p>                        
                            <p class="paragraph" style="display:none;">知识服务平台基于知识发现、应用集成、开放互联的设计理念，摒弃数据资源的简单堆砌，强调数据利用，通过云分析平台和智能报告生成平台，将一系列与数据密切关联的应用，集成到一个平台中。通过对文献中指标的提取与挖掘分析，发现主题与指标间的关联关系，行程知识发现图谱。提供知识分享、数据上传等个性服务，实现不同类型资源间互通互联。</p>                        
                        </div>
                        <div class="wheelsWrap">
                            <div class="wheelTitle">
                                <div class="wheelName cleft" v-for="(v,k) in knowListData" :key="k">
                                    <div class="wheelTitImg">
                                        <img :src="v.src" :alt="v.title">
                                    </div>
                                    <div class="wheelTit">{{v.title}}</div>
                                    <div class="wheelPname">{{v.name1}}</div>
                                    <div class="wheelPname">{{v.name2}}</div>
                                </div>
                            </div>
                            <Knowdemo></Knowdemo>
                        </div>
                    </div>
                    <!-- Alipay -->
                    <div class="problock" v-show="sideActive==8">
                        <div class="CommontTitle">
                            <div class="com_TitleName">
                                <span class="com_Name">付费数据库</span>
                            </div>
                        </div>
                        <div class="wheelsWrap">
                            <div class="alipay">
                                <div class="itemAlipay" v-for="(item,index) in sidechild.slice(0,5)" :key="index">
                                    <div class="idbleft">{{item.name}} :</div>
                                    <div class="idbright">
                                        <span v-for="(i,t) in item.cubes" :key="t" @click.stop="showAlipay">
                                            <span v-if="i.id">{{i.fullName}}</span>
                                        </span>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
            <div class="clear"></div>
        </div>
        <Footer></Footer>
        <a-modal
          :visible="visible"
          :footer="null"
          title="交易支付"
          centered
          @cancel="visible=false"
        >
        <div style="textAlign: center">
            <img v-if="urlData=='sozdata'" style="width: 300px;" src="../../public/img/sozAlipay.jpg" alt="">
            <img v-else style="width: 300px;" src="../../public/img/epsAlipay.jpg" alt="">
        </div>
        </a-modal>
    </div>
</template>
<script>
import Header from './commons/Header.vue';
import Footer from './commons/Footer.vue';
import Epsdemo from './commons/whells/eps1.vue';
import Wgdemo from './commons/whells/wg1.vue';
import Cjjjddemo from './commons/whells/cjjjd1.vue';
import Gmlqdemo from './commons/whells/gmlq1.vue';
import Qyjjdemo from './commons/whells/qyjj.vue';
import Knowdemo from './commons/whells/know1.vue';
import Vuetml from './commons/Vuetml.vue';
import {
    ajaxGet,
    urlKnowBak,
    urlEpsBak,
    urlGmlqBak,
    urlQyjjBak,
    urlCjjjdBak,
    urlWgBak,
    urlSoz,
    urlDfinder,
    getCookie,
} from "../until/common";
export default {
    name:'Product',
    components:{
        Header,
        Footer,
        Epsdemo,
        Wgdemo,
        Cjjjddemo,
        Gmlqdemo,
        Qyjjdemo,
        Knowdemo,
        Vuetml
    },
    data() {
        return {
            visible:false,//alipay
            urlData:'',

            dataEPSobj:{},
            olap_url:null,
            know_url:null,
            gmlq_url:null,
            cjjjd_url:null,
            wgjj_rul:null,
            




            epsTitle:false,
            sideActiveBack:1,
            sideActive:1,//1,
            sideActiveItem:0,//0,false
            sideActiveChild:0,
            nameobj:{},
            epsCubeObj:{},
            sideList:[
                {
                    id:1,
                    name:'EPS数据平台',
                    check:false,
                },
                {
                    id:2,
                    name:'中国微观经济数据查询系统',
                    check:false,
                },
                {
                    id:6,
                    name:'知图平台',
                    check:false,
                },{
                    id:3,
                    name:'知识服务平台',
                    check:false,
                },
                {id:4,name:'长江经济带大数据平台',check:false,},
                {id:5,name:'中国革命老区大数据平台',check:false,},
                {id:7,name:'中国区域研究数据支撑平台',check:false,},
                // {id:8,name:'付费数据库',check:false,},
                /* {
                    id:4,
                    name:'国家战略科研支撑平台',
                    check:false,
                    child:[
                        {id:401,name:'长江经济带大数据平台'},
                        {id:402,name:'中国革命老区数据平台'}
                    ]
                }, */
            ],
            sidechild:require('../../public/json/EpsCubes.json'),
            
            epsListData:[
                {id:1,title:'数据全面',name1:'拥有四大核心版块',name2:'数据覆盖全国&国内全部市县',src:'img/eps1.png'},
                {id:2,title:'检索高效',name1:'指标多维快捷查询',name2:'跨库&库内&高级三种检索方式',src:'img/eps2.png'},
                {id:3,title:'工具专业',name1:'强大的经济计量系统',name2:'支持自有数据云端上传分析',src:'img/eps3.png'},
            ],
            
            wgListData:[
                {id:1,title:'数据资源',name1:'工业企业&海关企业&创新企业',name2:'等多类微观数据',src:'img/wg1.png'},
                {id:2,title:'数据查询',name1:'支持单一年份数据查询',name2:'连续多年份时间序列查询',src:'img/wg2.png'},
                {id:3,title:'数据匹配',name1:'实现跨库企业匹配',name2:'为跨领域实证研究提供数据支持',src:'img/wg3.png'},
            ],
            
            knowMapListData:[
                {id:1,title:'信息素养',name1:'构建教、学、练、测、评信息素养教育生态闭环',src:'img/ztu1.png'},
                {id:2,title:'论文推荐',name1:'图情期刊精选最新优质论文进行推荐与点评',src:'img/ztu2.png'},
                {id:3,title:'图情视界',name1:'同行分享知识、经验、观点、见解与思考',src:'img/ztu3.png'}
            ],
            
            cjjjdListData:[
                {id:1,title:'指数中心',name1:'城市包容性发展',name2:'绿色发展指数及高质量发展指数',src:'img/cjjjd1.png'},
                {id:2,title:'数据中心',name1:'统一检索与多维筛选',name2:'包含长江经济带24类主题数据',src:'img/cjjjd2.png'},
                {id:3,title:'统计分析',name1:'强大的分析预测模型库',name2:'包含40余种常见分析模型',src:'img/cjjjd3.png'},
            ],
           
            gmlqListData:[
                {id:1,title:'数据监测',name1:'对革命老区的重要',name2:'经济指标进行观测和预警',src:'img/gmlq1.png'},
                {id:2,title:'指数发布',name1:'发布革命老区的5个发展指数',name2:'10个关联指数',src:'img/gmlq2.png'},
                {id:3,title:'空间展示',name1:'多种可视化方式展示指标数据',name2:'与地图结合进行空间切换',src:'img/gmlq3.png'},
            ],
            qyjjListData:[
                {id:1,title:'数据面板',name1:'支持下钻至县级的全屏地图',name2:'直观展现各地区经济社会发展状况',src:'img/qyjj1.png'},
                {id:2,title:'指标全面',name1:'收录省级指标12000+',name2:'市级指标1800+，县级指标210+',src:'img/qyjj2.png'},
                {id:3,title:'快速检索',name1:'提供一键筛选“十四五”国家',name2:'重点规划区域、热点研究区域',src:'img/qyjj3.png'},
            ],
           
            knowListData:[
                {id:1,title:'主题发现',name1:'庞大的主题词库',name2:'覆盖经济核刊全部研究主题',src:'img/know1.png'},
                {id:2,title:'文献计量',name1:'基于共线关联统计',name2:'多维度的文献计量分析服务',src:'img/know2.png'},
                {id:3,title:'智能报告',name1:'智能分析统计表格',name2:'一键生成图文并茂分析报告',src:'img/know3.png'},
            ],
            linkList:[
                'http://1313199104.vod2.myqcloud.com/59cc12c2vodcq1313199104/7b29d7421397757886973722927/YXh6MLRGaAoA.mp4?t=1900f9f4c47&sign=72c7d1301f7acffaed51adf7eb932622',
                'http://1313199104.vod2.myqcloud.com/59cc12c2vodcq1313199104/e39d91bb1397757886971834054/jchn4WfqCp8A.mp4?t=1900f9f4e04&sign=2ba55bd5a1066909b795a692c6156de7'
            ],
            pdfList:[
                'https://zhitu.sozdata.com/M00/00/E8/wKgADWXmtIaAS2YBAD-y3Exx8fs698.pdf',
                'https://zhitu.sozdata.com/M00/00/FA/wKgADWXz8gmAMhEVABRzhboyT6Q565.pdf'
            ]
        }
    },
    computed:{
        epsSid() {
            return getCookie('eps_sid');
        }
    },
    methods:{
        tolink(e){
            window.open(this.linkList[e], '_blank');
        },
        toPDF(e){
            window.open(this.pdfList[e], '_blank');
        },
        /* getGroupClicks(item){
            this.sideActive = item.id;
            this.sideActiveBack = item.id;
            if(item.id==1){
                this.sideActiveItem = 0;
                this.sideActiveChild = 0;
            } 
            if(item.id==4){
                this.sideActiveItem = 401;
            }
        }, */
        //付款码弹框
        showAlipay(){
            this.visible=true
        },
        getEPSlist(){
            this.epsTitle = true;
        },
        getEPSlists(){
            this.epsTitle = false;
        },
        getItemEPSlist(v,i){
            this.nameobj = i;
            this.sideActiveItem = v.id;
            this.sideActiveChild = i.id;
            this.epsTitle =false;
            i.name = i.fullName;
            this.epsCubeObj = i;
            this.sideActive=1;
        },
        getGroupClick:function(item){    
            this.sideActive = item.id;
            this.sideActiveBack = item.id;
            this.nameobj = {};
            if(item.id==1){
                this.sideActiveItem = 0;
                this.sideActiveChild = 0;
            } 
            if(item.id==4){
                this.sideActiveItem = 401;
            }
			this.sideList.forEach(function(v){
                if(v.child){
                    v.child.forEach(function(item){
                        item.check = false
                    })
                }
            });
            for(var i=0; i<this.sideList.length; i++){
				if(item.id == this.sideList[i].id){
					item.check = !item.check;
				}else{
					this.sideList[i].check = false;
				}
			}
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        getItemClick:function(item,val){
            
            this.sideActiveItem = val.id;
            for(var i=0; i<item.child.length; i++){
				if(val.id == item.child[i].id){
                    val.check = !val.check;//true;
                    if(val.check){
                        if(val.childlist){
                            this.sideActiveChild = val.childlist[0].id;
                        }
                    } else{
                        this.sideActive = 1;
                        this.sideActiveItem = 0;
                        this.sideActiveChild = 0;
                    }
                }else{
                    item.child[i].check = false;
				} 
            }
            if(item.id==4&&val.id==401){
                this.sideActive = 4;                
            }else if(item.id==4&&val.id==402){
                this.sideActive = 4;
                this.sideActiveBack = 6;                
            }
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        getChindClick:function(val,v){
            this.sideActiveChild = v.id;
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
    },
    created(){
        
    },
    mounted(){
        if(this.$route.query.alipay){
            this.sideActive=8
        }
        if(this.$route.query.side){
            this.sideActive=this.$route.query.side
        }
        
        if(document&&document.location.origin===urlSoz){
            this.urlData = 'sozdata'
        }else if(document&&document.location.origin===urlDfinder){
            this.urlData = 'sozdata'
        }
        else {
            this.urlData = 'epsdata'
        }

        this.know_url = urlKnowBak;//(http://kdd.epsnet.com.cn/)
        this.olap_url = urlEpsBak;//(http://olap.epsnet.com.cn/)
        this.gmlq_url = urlGmlqBak;//(http://crod.epsnet.com.cn/)
        this.qyjj_url = urlQyjjBak;//(http://cnrrd.sozdata.com/)
        this.cjjjd_url = urlCjjjdBak;//(http://yreb.sozdata.com/)
        this.wgjj_rul = urlWgBak;//(http://microdata.sozdata.com/)
        ajaxGet("cubeStatNums").then(res => {
            if (res&&res.data) {
                this.dataEPSobj = res.data;
            }
        });      
    },
}
</script>
<style lang="scss" scoped>
    .wrap{
        color: #666;
        padding: 20px 38px;
        .textTip{
            font-size: 16px;
            text-indent: 2em;
            color: #666;
        }
        .advantage{
            margin-top: 42px;
            .text{
                font-size: 18px;
                color: #E75934;
            }
            .advantageContent{
                margin-top: 43px;
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                .item{
                    flex: 1; 
                    box-sizing: border-box; 
                    padding: 10px; 
                    margin: 5px; 
                    margin-top: 0;
                    padding-top: 0;
                    text-align: center; 
                    .image{
                        width: 60px;
                        height: 60px;
                        margin: 0 auto 26px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .advTit{
                        font-size: 18px;
                        color: #666;
                        font-weight: bold;
                    }
                    .advTxt{
                        margin-top: 20px;
                        color: #666;
                        text-align: justify;
                    }
                }
            }
        }
        .feature{
            margin-top: 60px;
            .text{
                font-size: 18px;
                color: #E75934;
            }
            .featureContent{
                margin-top: 43px;
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                .item{
                    flex: 1; 
                    box-sizing: border-box; 
                    padding: 17px 40px 20px 30px; 
                    margin: 0 10px 0; 
                    border: 1px solid #e8e8e8;
                    border-left: 2px solid #E75934; 
                    text-align: justify; 
                    .tit{
                        font-size: 18px;
                        font-weight: bold;
                        color: #666;
                    }
                    &:first-child{
                        margin-left:0;
                    }
                    &:last-child{
                        margin-right:0;
                    }
                    .desc{
                        margin-top: 12px;
                        color: #666;
                    }
                    .tags{
                        margin-top: 16px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        span{
                            color: #666;
                            display: inline-block;
                            flex: 0 0 calc((100% - 2 * 20px) / 2); /* 每个项目的宽度（包括间距） */  
                            margin-right: 20px; 
                            margin-top: 19px;
                            &:nth-child(2n) {  
                                margin-right: 0; 
                            }  
                            &::before{
                                content: "";
                                display: inline-block; 
                                width: 4px; 
                                height: 4px;   
                                background-color: #E75934;
                                margin-right: 5px;   
                                position: relative;
                                top: -2px;
                            }
                        }
                    }
                }
            }
        }
        .guide{
            margin-top: 49px;
            .text{
                font-size: 18px;
                color: #E75934;
            }
            .guideContent{
                margin-top: 26px;
                display: flex;
                justify-content: flex-start;
                font-size: 16px;
                flex-wrap: wrap;
                .item{
                    width: 370px;
                    height: 220px;
                    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
                    border-radius: 4px;
                    flex: 0 0 calc((100% - 2 * 20px) / 3); /* 每个项目的宽度（包括间距） */  
                    margin-right: 20px; 
                    margin-top: 17px;
                    &:nth-child(3n) {  
                        margin-right: 0; 
                    }  
                    .imgWrap{
                        width: 100%;
                        height: 170px;
                        cursor: pointer;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .itemBtm{
                        height: 50px;
                        line-height: 50px;
                        display: flex;
                        justify-content: space-between;
                        margin: 0 20px;
                        span{
                            color: #666;
                        }
                        .down{
                            color: #E75934;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .contenter .prowrap .procontent .problock .paragraph-box .paragraph{
        text-align: justify;
    }
</style>